
function netInfoBackdrop(_CONFIG) {
  var element = $('body');

  element.addClass('has-branding');
  $('.leaderboard-wrapper').hide();

  element.css({
    backgroundImage: 'url(' + _CONFIG.SRC + ')',
    backgroundColor: _CONFIG.bgColor,
    backgroundRepeat: _CONFIG.bgRepeat,
    backgroundPosition: _CONFIG.bgPoss,
    backgroundSize: 'auto',
    borderTop: _CONFIG.borderTop
  });

  var clickArea = $('<a id="clickArea01" />'),
    clickTag01 = _CONFIG.COUNT + encodeURI(_CONFIG.URL);

  element.append(clickArea);

  clickArea.css({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 1,
    cursor: 'pointer'
  });

  clickArea.on('click', function () {
    window.open(clickTag01, 'blank')
  });

  if (_CONFIG.conterImage) (new Image).src = _CONFIG.conterImage;
}

// netInfoBackdrop({
//   SRC: 'https://bimg.abv.bg/ohnamama/assets/images/content-images/backdrop.png',
//   bgColor: '#fff',
//   bgRepeat: 'no-repeat',
//   bgPoss: 'center top',
//   borderTop: 'none',
//   COUNT: 1,
//   URL: 'https://google.com',
//   conterImage: 'conter'
// })