function showNative(obj) {
  let blockForReplace;
  let afterParagraph;
  let blockHtml = `
  <a href="${obj.url}" class="object-fit img-wrapper" target="_blank">
    <img src="${obj.thumbSquared}" alt="${decodeURIComponent(obj.title)}">
  </a>
  <div class="text-wrapper">
    <div class="main-info">
        <a href="${obj.url}" class="native-link" target="_blank">
          <svg class="icon" width="21" height="21" viewBox="0 0 21 21">
            <use href="/resources/assets/images/defs/svg/sprite.defs.svg#paid-article"></use> 
          </svg>
          ${obj.hostSite}
        </a>
        <h3 class="title"><a href="${obj.url}" target="_blank">${decodeURIComponent(obj.title)}</a></h3>
    </div>
  </div>`

  if ($('.home-articles-wrapper .article-block').length > 0) {
    blockForReplace = $('.home-articles-wrapper .article-block').eq(2);
    replaceBlock(blockForReplace, blockHtml);
  }

  if ($('.category-articles-wrapper .article-block').length > 0) {
    blockForReplace = $('.category-articles-wrapper .article-block').eq(2);
    replaceBlock(blockForReplace, blockHtml); 
  }

  if ($('.article-content').length > 0 && !$('.article-content').hasClass('article-powered-by')) {
    if ($('.article-content .article-text > p').length < 5) {
      afterParagraph = $('.article-content .article-text > p').last();
    } else {
      afterParagraph = $('.article-content .article-text > p').eq(4);
    }
    
    addBlock(afterParagraph, blockHtml);
  }

}

function replaceBlock(blockForReplace, blockHtml) {
  let finalBlockHtml = 
  `<article class="native-ad article-block sm-vertical">
    ${blockHtml}
  </article>`

  blockForReplace.after(finalBlockHtml);
  blockForReplace.remove();

}

function addBlock(afterParagraph, blockHtml) {
  
  let finalBlockHtml = 
  `<div class="article-inner-block native-banner">
    <article class="article-block horizontal-article small">
      ${blockHtml}
    </article>
  </div>`;

  afterParagraph.after(finalBlockHtml);

}