function parallaxImage(banner, pImage, xlHeight) {
  var imgTop = 0,
      scrollPos = $(window).scrollTop(),
      heightOfScreen = $(window).height(),
      startScroll = banner.offset().top - ((heightOfScreen / 3) * 2) - 100;

  if (scrollPos >= startScroll) {
    imgTop = ((pImage.height() - xlHeight) / heightOfScreen) * (scrollPos - startScroll);

    if (imgTop < 0) { 
      imgTop = 0; 
    }
    if (imgTop > pImage.height() - xlHeight) { 
      imgTop = (pImage.height() - xlHeight); 
    }

    pImage.css({
      "top": -(imgTop) + "px"
    });
  }
}

function loadBackDrop(imageUrl01, imageUrl02, clickTag, xlHeight, xlWidth) {
  xlWidth = xlWidth || '360';
  xlHeight = xlHeight || '250';


  if ($('.article-text').length) {

    $('.for-branding').before(`
      <div class="bDrop-top" 
        style="width: 100%; max-width: ${xlWidth}; margin: 10px auto -10px; height: 100px; background: transparent url(${imageUrl01}) no-repeat center top; ">
        <a style="width: 100%; height: 100%; display: block;" href="${clickTag}" target="_blank"></a>
      </div>
    `);

    $('.article-text p:nth-of-type(2)').after(`
      <div id="parallax-banner" 
        style="clear: both; margin: 0 auto 24px; position: relative; width: 100%; max-width: ${xlWidth}; height: 250px; overflow: hidden;">
        <a href="${clickTag}" target="_blank" style="display: block; width: 100%; height: 100%;">
          <img style="position: absolute; width: 100%; height: auto; top: 0;" src="${imageUrl02}" class="attachment-parallax" alt="--" style="top: 0px;">
        </a>
      </div>
    `);
    $('.leaderboard-wrapper').hide();
  }

  var banner = $("#parallax-banner"),
    pImage = banner.find("img");

  $(window).scroll(function () {
    parallaxImage(banner, pImage, xlHeight);
  });

}

function loadParallaxBanner(imageUrl01, clickTag, xlWidth, xlHeight) {

  xlWidth = xlWidth || '360';
  xlHeight = xlHeight || '300';

  if ($('.article-text').length) {

    $('.article-text p:nth-of-type(2)').after(`
      <div id="parallax-banner" style="clear: both; margin: 0 auto 24px; position: relative; width: 100%; max-width: 100%; height: 300px; overflow: hidden;">
        <a href="${clickTag}" target="_blank" style="display: block; width: 100%; height: 100%;">
          <img style="position: absolute; width: 100%; height: auto; top: 0;" src="${imageUrl01}" class="attachment-parallax" alt="--" style="top: 0px;">
        </a>
      </div>`);

  }

  var banner = $("#parallax-banner"),
    pImage = banner.find("img");

  $(window).scroll(function () {
    parallaxImage(banner, pImage, xlHeight);
  });

}

// loadBackDrop('https://m3.netinfo.bg/media/images/41486/41486771/728-409-bremennost.jpg',
// 'https://ohnamama.bg/resources/assets/images/image.png',
// 'https://google.com');

// loadParallaxBanner(
// 'https://ohnamama.bg/resources/assets/images/image.png',
// 'https://google.com');